import type { ItemModelType } from 'o365-dataobject';
import { DataObject } from 'o365-dataobject';
import { API } from 'o365-modules';

declare module "o365-dataobject" {
    interface DataObject<T> {
        bulkUpdate: BulkUpdate<T>;
    }
}

Object.defineProperty(DataObject.prototype, 'bulkUpdate', {
    get() {
        if (this._bulkUpdate == null) {
            this._bulkUpdate = new BulkUpdate(this);
        };
        return this._bulkUpdate;
    },
});



export default class BulkUpdate<T extends object = any> {
    private _dataObject: DataObject<T>;

    private _onBeforeBulkUpdate?: (options: {
        bulkItem: T,
        selectedItems: ItemModelType<T>[],
        bulkFields: string[],
    }, requestData: IBulkUpdateRequest<T>) => Promise<true | IBulkUpdateRequest<T>>;

    constructor(dataObject: DataObject<T>) {
        this._dataObject = dataObject;
    }

    /** Before bulk update custom handler. Return true if should skip the default handler  */
    setOnBeforeBulkUpdate(handler: (options: {
        bulkItem: T,
        selectedItems: ItemModelType<T>[],
        bulkFields: string[]
    }, requestData: IBulkUpdateRequest<T>) => Promise<true | IBulkUpdateRequest<T>>) {
        this._onBeforeBulkUpdate = handler;
    }

    async doBulkUpdate(options) {
        if (this._dataObject.hasPropertiesData && options.bulkFields?.some((field: string) => field.startsWith('Property.'))) {
            return this._dataObject.propertiesData.bulkUpdate({
                bulkItem: options.bulkItem,
                bulkFields: options.bulkFields,
                selectedItems: options.selectedItems.value
            });
        }
        let valuesObject = {};
        Object.keys(options['bulkItem']).forEach(function (item) {
            valuesObject[item] = options['bulkItem'][item];
        });

        let uniqueColumnID = options['uniqueColumnID'] || this._dataObject.fields.uniqueField;
        let uniqueColumnValues = options['selectedItems'].value.map(function (item) {
            return options['selectedItems'].value[0].appId ? item[uniqueColumnID] : item;
        }).toString();

        let requestData = {
            "viewName": this._dataObject.viewName,
            "uniqueTable": this._dataObject.uniqueTable,
            "values": valuesObject,
            "operation": "update",
            "bulk": true,
            "key": uniqueColumnID,
            "ids": uniqueColumnValues
        };

        if (this._onBeforeBulkUpdate != null) {
            const updated = await this._onBeforeBulkUpdate(options, requestData);
            if (updated === true) { return {}; }
            requestData = updated;
        }

        try {
            const response = await API.request({
                requestInfo: '/nt/api/data',
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(requestData),
                responseBodyHandler: API.ResponseHandler.Raw
            });

            const jsonResponse = await response.json();

            // Refresh all updated items
            const key = requestData.key;
            const ids = requestData.ids;
            const promises = this._dataObject.storage.data.filter((item: any) => ids.includes(item[key])).map(item => {
                return this._dataObject.refreshRow(item.index);
            });
            if (this._dataObject.batchDataEnabled) {
                this._dataObject.batchData.data.filter((item:any) => ids.includes(item[key])).forEach(item => promises.push(this._dataObject.refreshRow(item.index)));
            }
            await Promise.all(promises);
            return jsonResponse;
        } catch (ex) {
            return { error: ex?.message };
        }
    }
}

interface IBulkUpdateRequest<T extends object = any> {
    'viewName': string,
    'uniqueTable': string,
    'values': T,
    'operation': 'update',
    'bulk': true,
    'key': string,
    'ids': string,
};